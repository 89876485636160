<template>
  <UtilRendererIcon
    v-if="name"
    :key="uniqueKey"
    :name="name"
    class="ion-icon"
  />
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },
});

/*
  Rerender the asnyc child component when the icon name changes.
*/
const { getUid } = useUuid();
const uniqueKey = ref(getUid());

watch(() => props.name, () => {
    uniqueKey.value = getUid();
});
</script>

<style lang="scss">
.ion-icon {
    width: 20px;
    height: 20px;
}

.block-header__account {
    .ion-icon {
        path {
            stroke: var(--c-primary);
        }
    }
}

.page-hellotess {
    .ion-icon {
        width: 135px;
        height: 100%;
    }
}

.atom-drop-area__preview {
    .ion-icon {
        width: 24px;
        height: 24px;
    }
}

.form-payment-methods__wrapper__container__item {
    .ion-icon {
        width: 24px;
        height: 24px;
    }
}

.block-form-terminals__worldline__image,
.block-form-pos-systems__hellotess__image {
    .ion-icon {
        width: 218px;
        height: 100%;
    }
}

.app-alert-box__content {
    .ion-icon {
        width: 46px;
        height: 46px;
    }
}

.block-form-terminals__connected-terminal {
    .ion-icon {
        width: 27px;
        height: 16px;
    }
}

.atom-input-select__option-label,
.atom-input-select__label-icon {
    .ion-icon {
        width: 27px;
        height: 16px;
    }

}

.block-header__nav-list-item {
    .ion-icon {
        path: {
            transition: all 0.4s ease-in-out;
        }
    }
    &.router-link-exact-active {
        .ion-icon {
            path {
                fill: var(--c-primary);
                transition: all 0.4s ease-in-out;
            }
        }
    }
}
</style>
