import uuid4 from 'uuid4';

export default () => {
    const getUid = () => uuid4();

    const isValidUid = (id) => uuid4.valid(id);

    return {
        getUid,
        isValidUid,
    };
};
